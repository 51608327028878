<template>
  <div class="main">
    <div class="sub" v-if="homeData.myMajor">
      <div class="sub-title-view">
        <div class="sub-title" @click="goMine">我的专业</div>
        <div class="icon"></div>
      </div>
      <div class="sub-content-card">
        <img :src="homeData.myMajor.thumbUrl" class="course-img">
        <div class="course-info-view">
          <div class="level-label-view">
            <!-- <div class="level-label">高职 Higher vocational college</div> -->
            <div></div>
          </div>
          <div class="course-name">{{homeData.myMajor.name}}</div>
          <div class="course-summary">{{homeData.myMajor.summary}}</div>
          <div class="goto-study" @click="() => goCourseList(homeData.myMajor.id)">去学习</div>
        </div>
      </div>
    </div>
    <div class="sub">
      <div class="sub-title-view">
        <div class="sub-title" @click="goMine">最近学习</div>
        <div class="icon"></div>
        <div class="check-all" @click="goCourseHistory">查看全部</div>
      </div>
      <div class="empty-fragment-view" v-if="homeData.courseBrowsingHistory == null || homeData.courseBrowsingHistory.length == 0">
        <div class="label">您还没有进行碎片学习</div>
        <!-- <div class="goto-study" @click="goCourseHistory">前去学习</div> -->
      </div>
      <div class="fragemnt-history-view" v-if="homeData.courseBrowsingHistory != null && homeData.courseBrowsingHistory.length != 0">
        <div class="fragment-history-item" @click="() => goKnowledgeDetail(item.id)" v-for="item in homeData.courseBrowsingHistory" :key="`${item.id}`">
          <img :src="item.thumbUrl" class="fragment-history-icon">
          <div class="fragment-name">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div style="margin-left: 20px;margin-right: 20px;">
      <a href="//v.fmyundianji.com/chat">
        <img src="/images/ai.png" style="width: 100%;"/>
      </a>
    </div>
    <!-- {{ testData }} -->
    <div class="sub">
      <div class="sub-title-view">
        <div class="sub-title">精品专业</div>
        <div class="icon"></div>
        <div class="check-all"  @click="goMajorList">全部专业</div>
      </div>
      <div class="major-view">
        <div class="major-card h-card" v-if="homeData.topMajor && homeData.topMajor.length > 0">
          <div class="level-label">高职</div>
          <div class="major-list">
            <div class="major-item" v-for="(item, i) in homeData.topMajor[0].rankList" :key="`${item.id}`" @click="() => goCourseList(item.id)">
              <img class="icon" src="../assets/number_icon_y.png"/>
              <div class="number-label">{{ i+1 }}</div>
              <div class="major-name">{{item.name}}</div>
            </div>
            <div class="check-all">查看全部 ></div>
          </div>
        </div>
        <div class="major-card m-card" v-if="homeData.topMajor && homeData.topMajor.length > 0">
          <div class="level-label">中职</div>
          <div class="major-list">
            <div class="major-item" v-for="(item, i) in homeData.topMajor[1].rankList" :key="`${item.id}`" @click="() => goCourseList(item.id)">
              <img class="icon" src="../assets/number_icon_b.png"/>
              <div class="number-label">{{ i+1 }}</div>
              <div class="major-name">{{item.name}}</div>
            </div>
            <div class="check-all">查看全部 ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub">
      <div class="sub-title-view">
        <div class="sub-title">精品课程</div>
        <div class="icon"></div>
        <div class="check-all" @click="goCourseSearch">全部课程</div>
      </div>
      <div class="course-view">
        <div class="course-card h-card" v-if="homeData.topCourse && homeData.topCourse.length > 0">
          <div class="level-label">高职</div>
          <div class="course-list">
            <div class="course-item"  v-for="(item, i) in homeData.topCourse[0].rankList" :key="`${item.id}`" @click="() => goKnowledgeDetail(item.id)">
              <img class="icon" :src="item.thumbUrl"/>
              <div class="course-name-view">
                <div class="course-name">{{item.name}}</div>
                <div class="level-lite-label">高职</div>
              </div>
            </div>
            <div class="check-all"  @click="goCourseSearch">查看全部 ></div>
          </div>
        </div>
        <div class="course-card m-card" v-if="homeData.topCourse && homeData.topCourse.length > 0">
          <div class="level-label">中职</div>
          <div class="course-list">
            <div class="course-item"  v-for="(item, i) in homeData.topCourse[1].rankList" :key="`${item.id}`" @click="() => goKnowledgeDetail(item.id)">
              <img class="icon" :src="item.thumbUrl"/>
              <div class="course-name-view">
                <div class="course-name">{{item.name}}</div>
                <div class="level-lite-label">中职</div>
              </div>
            </div>
            <div class="check-all"  @click="goCourseSearch">查看全部 ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FragmentApi from '@/api/src/fragment/api/FragmentApi.js' 
import FragmentHomePageVo from '@/api/src/fragment/model/FragmentHomePageVo'
const fragmentApi = new FragmentApi()

export default {
  name: 'Container',
  data() {
    return {
      navTitle: '首页',
      homeData:new FragmentHomePageVo(),
      testData:null,
      jsBridgeObj:null
    }
  },

  async mounted(){
    // document.title = '云碎片';
    // window.jsBridge.postNotification('CLIENT_TOOLBAR_TITLE', {'webTitle': '云碎片'});
    
    this.homeData = {}
    var data = await fragmentApi.getHomePage()
    console.log(data)
    this.homeData = data
    // let bridge = new CXJSBridge3T('171712993435545N6m2Ot+OYkKl0Pg7ruY1I/prXK/vPLxT5TTFCEpbzHXibFpbOCtccvNEDSKW6oCtPD6SjtnfeRMRIE4/KJFuH35yG50h6QnQuWq4e/+0rqAfcTXq2Nwkg+20u/vXMYXVqZmD2M6slRt2Br7q0Xq6OF/MUWNdlg8NvOgtNlFu/Ro=')
    // bridge.init()

    // try {
    //     this.jsBridgeObj = jsBridge
    //     var that = this
    //     jsBridge.bind('CLIENT_DEVICE_ID', function(object){
    //       that.testData = object
    //     });
    //     jsBridge.postNotification('CLIENT_DEVICE_ID',{});//获取到设备唯一id
    // } catch (e) {
    //     console.log(e)
    // }
    
  },

  
  methods: {
    
    goMajorList() {
      // jsBridge.postNotification('CLIENT_OPEN_URL', {
      //   "loadType" : 1,
      //   "webUrl" : "https://v.fmyundianji.com/fragment/8019749682911428608/8019749420968755200/%E6%B0%91%E8%88%AA%E4%BA%BA%E5%91%98%E4%B8%AA%E4%BA%BA%E5%BD%A2%E8%B1%A1/0/0/-1?appId=f32795d83e5e440b86f8ea868b289d6f&knowledgeId=8019749695309791232&courseStandardId=8019749420968755200&knowledgeName=%3F%3F%3F%3F%3F%3F%3F%3F&index=0&subIndex=1&fid=8159725581815447575&code=u8d9qYea&state=190729",
      //   "toolbarType" : 2
      // });
      this.$router.push({name: 'major'})
    },
    goCourseSearch() {
      this.$router.push({name: 'courseSearch'})
    },
    goCourseList(majorId) {
      this.$router.push({name: 'courseList', params: {majorId}})
    },
    goKnowledgeDetail(courseStandardId) {
      this.$router.push({name: 'knowledgeMap', query: {courseStandardId}})
    },
    goCourseHistory() {
      this.$router.push({name: 'courseHistory'})
    },
    goMine() {
      this.$router.push({name: 'mine'})
    },
  }
}
</script>

<style scoped lang="less">
.main{
  display: flex;
  flex-direction: column;
}

.sub{
  display: flex;
  flex-direction: column;
  margin: 20px;
  .sub-title-view{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .sub-title{
      font-weight: 600;
      font-size: 26px;
      color: #333333;
    }
    .icon{
      width: 8px;
      height: 8px;
      box-shadow: 1px 2px 4px rgba(255,241,38,0.2);
      border-radius: 50%;
      border: 2px solid #FF8826;
      margin-bottom: 5px;
      margin-left: 10px;
    }
    .check-all{
      font-weight: 400;
      font-size: 10px;
      color: #999999;
      margin-left: auto;
    }
  }
  .sub-content-card{
    display: flex;
    flex-direction: row;
    box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.03);
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    .course-img{
      width: 94px;
      height: 127px;
      background: rgba(241, 241, 241, 0);
      border-radius: 8px;
    }
    .course-info-view{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-right: 20px;
    }

    .level-label-view{
      display: flex;
      flex-direction: row;
      .level-label{
        font-weight: 400;
        font-size: 8px;
        color: #333333;
        border-bottom: 1px solid rgb(53, 53, 53);
      }
    }

    .course-name{
      font-weight: 600;
      font-size: 22px;
      color: #333333;
      margin-top: 10px;
    }

    .course-summary{
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      margin-top: 10px;
    } 
    .goto-study{
      width: 60px;
      height: 24px;
      background: linear-gradient(90deg, #FF8826 0%, #F7B277 100%);
      border-radius: 12px;
      color: white;
      font-weight: 400;
      font-size: 9px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      margin-top: auto;
    }
  }

  .fragemnt-history-view{
    display: flex;
    flex-direction: column;
    background: rgba(255,255,255,0.39);
    box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.03);
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    .fragment-history-item{

      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      .fragment-history-icon{
        width: 20px;
        height: 24px;
        background: rgba(0,0,0,0);
        border-radius: 2px;
      }
      .fragment-name{
        font-weight: 500;
        font-size: 12px;
        color: #333333;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

  }
  
  .empty-fragment-view{
    padding: 10px;
    margin-top: 20px;
    height: 116px;
    background: rgba(255,185,125,0.13);
    box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.03);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .label{
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 12px;
      color: #FF8826;
    }
    .goto-study{
      width: 60px;
      height: 24px;
      background: linear-gradient(90deg, #FF8826 0%, #F7B277 100%);
      border-radius: 12px;
      color: white;
      font-weight: 400;
      font-size: 9px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .major-view{
    display: flex;
    flex-direction: row;
    width: 100%; /* 设置宽度，确保div可以水平滚动 */
    overflow-x: auto; /* 允许横向滚动 */
    flex-wrap: nowrap; /* 禁止换行 */
    margin-top: 20px;
    padding-bottom: 20px;
    .major-card{
      width: 220px;
      height: 230px;
      flex: 0 0 auto; /* 确保所有项目都具有相同的大小 */
      .level-label{
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        margin-top: 20px;
        margin-left: 20px;
      }
      .major-list{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .major-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          min-height: 30px;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 5px;
          .number-label{
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            margin-bottom: 5px; 
            margin-left: -15px;
          }
          .icon{
            width: 15px;
            height: 15px;
            
            margin-top: 10px;
          }
          .major-name{
            font-weight: 600;
            font-size: 14px;
            color: #FFFFFF;
            margin-left: 10px;
          }
        }
        .check-all{
          font-weight: 400;
          font-size: 10px;
          color: #FFFFFF;
          margin-left: 20px;
          margin-top: 5px;
        }
      }
    }
    .h-card{
      flex: 0 0 auto; /* 确保所有项目都具有相同的大小 */
      width: 220px;
      height: 230px;
      background-image: url('../assets/fragment_major_card_y.png');
      background-size: cover; /* 控制背景图片大小 */
      background-position: center; /* 控制背景图片位置 */
    }
    .m-card{
      flex: 0 0 auto; /* 确保所有项目都具有相同的大小 */
      width: 220px;
      height: 230px;
      background-image: url('../assets/fragment_major_card_b.png');
      background-size: cover; /* 控制背景图片大小 */
      background-position: center; /* 控制背景图片位置 */
      margin-left: 20px;
    }
  }
  .course-view{
    display: flex;
    flex-direction: row;
    width: 100%; /* 设置宽度，确保div可以水平滚动 */
    overflow-x: auto; /* 允许横向滚动 */
    flex-wrap: nowrap; /* 禁止换行 */
    margin-top: 20px;
    padding-bottom: 20px;
    .course-card{
      width: 323px;
      height: 309px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.03);
      border-radius: 8px;
      flex: 0 0 auto; /* 确保所有项目都具有相同的大小 */
      .level-label{
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        margin-top: 20px;
        margin-left: 20px;
      }
      .course-list{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }
      .course-item{
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        margin-bottom: 5px;
        .icon{
          width: 50px;
          height: 40px;
        }
        .course-name-view{
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          margin-right: 10px;
          .course-name{
            font-weight: 600;
            font-size: 14px;
            color: #FFFFFF;
          }
          .level-lite-label{
            font-weight: 400;
            font-size: 8px;
            color: #FFFFFF;
            margin-top: auto;
          }
        }
      }
      .check-all{
        font-weight: 400;
        font-size: 10px;
        color: #FFFFFF;
        margin-left: 20px;
        margin-top: 5px;
      }
    }
    .h-card{
      background-color: #204585;
      color: white;
    }
    .m-card{
      background-color: #62455E;
      color: white;
      margin-left: 15px;
    }
  }
}
</style>
  